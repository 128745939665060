/**
 * @class UTM
 *
 * @description parse from URL params all existing UTM parameters and store them in the session storage
 *
 * @usage load this file singleton to get values UTM values
 *
 * @example UTM.get_tag('utm_source'); or UTM.utm_source;
 *
 * @available_tags utm_id, utm_source, utm_medium, utm_campaign, utm_term, utm_content
 *
 */

const UTM_STORAGE = "store-utm-parsed-event";
const AVAILABLE_UTM_TAGS = ["utm_id", "utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];
class UTM {
  constructor() {
    this._searchParams = {};
  }

  init() {
    // parse url params that are utm tags
    return new Promise((resolve) => {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.forEach((value, key) => {
        if (AVAILABLE_UTM_TAGS.includes(key)) this._searchParams[key] = value;
      });
      resolve();
    });
  }

  start() {
    // stop here if nothing was parsed
    if (Object.keys(this._searchParams).length == 0) return;

    // not yet stored, store data
    if (!this.stored && Object.keys(this._searchParams).length > 0) this.stored = this._searchParams;

    // re-store again if existing stored objects are different from parsed utm_tags data
    if (this.stored && JSON.stringify(this.stored) !== JSON.stringify(this._searchParams))
      this.stored = this._searchParams;
  }

  get_tag(utm_tag = "utm_source") {
    if (!this.stored) return null;
    return this.stored[utm_tag] ? this.stored[utm_tag] : null;
  }

  // getter & setter

  get utm_id() {
    return this.get_tag("utm_id");
  }

  get utm_source() {
    return this.get_tag("utm_source");
  }

  get utm_medium() {
    return this.get_tag("utm_medium");
  }

  get utm_campaign() {
    return this.get_tag("utm_campaign");
  }

  get utm_term() {
    return this.get_tag("utm_term");
  }

  get utm_content() {
    return this.get_tag("utm_content");
  }

  get stored() {
    return JSON.parse(sessionStorage.getItem(UTM_STORAGE));
  }
  set stored(value) {
    sessionStorage.setItem(UTM_STORAGE, JSON.stringify(value));
  }
}

const UTM_SINGLETON = new UTM();

export default UTM_SINGLETON;
