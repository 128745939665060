import AJAX from "@core/ajax";
import EMITTER from "@core/emitter";

const CHANGE_CURRENCY_PARAM = "change_currency";
const STORAGE = "language-preferences";

//
// Exemple of content in storage : {country: "CA", region: "CA-QC", currency: "CAD", units: "METRIC""}
//

class GeoLocalization {
  constructor() {
    this._country = null;
    this._currency = null;
    this._region = null;
    this._units = null;
    this._ready = false;
    this._url = new URL(window.location.href);

    this._read();

    // check for currency change param in URL and enforce it
    if( this._ready ) {
      const currency = this._url.searchParams.get(CHANGE_CURRENCY_PARAM);
      this._url = null;

      if( currency ) this.currency = currency;
    }
  }


  fetch() {
    if( this._ready ) return Promise.resolve();

    return AJAX
      .post("taigawp_geolocate")
      .then(response => {
        this._save(response);
        this._read(response);
      });
  }

  _read(value) {
    if( !value ) {
      const storage = sessionStorage.getItem(STORAGE);
      if( !storage ) return;

      value = JSON.parse(storage);
    }

    this._country = value.country;
    this._currency = value.currency;
    this._region = value.region;
    this._units = value.units;
    this._ready = true;
  }
  _save(value) {
    if( !value ) {
      value = {
        country: this._country,
        currency: this._currency,
        region: this._region,
        units: this._units,
      };
    }

    // check for currency change param in URL and enforce it
    if( this._url ) {
      const currency = this._url.searchParams.get(CHANGE_CURRENCY_PARAM);
      if( currency ) value.currency = currency;

      this._url = null;
    }

    sessionStorage.setItem(STORAGE, JSON.stringify(value));
  }

  // public method to change currency
  changeCurrency(currency) {
    // check if currency is the same as current, skip if so
    if(currency === this.currency) return;

    // set new currency and save, announce change to emitter
    this.currency = currency;
    this._save();

    // announce change to emitter
    EMITTER.emit("GeoLocalization.changeCurrency", currency);
  }

  // getter - setter
  get country() { return this._country; }
  set country(value) {
    if( this._country === value ) return;

    this._country = value;
    this._save();
  }

  get currency() { return this._currency; }
  set currency(value) {
    if( this._currency === value ) return;

    this._currency = value;
    this._save();
  }

  get region() { return this._region; }
  set region(value) {
    if( this._region === value ) return;

    this._region = value;
    this._save();
  }

  get units() { return this._units; }
  set units(value) {
    if( this._units === value ) return;

    this._units = value;
    this._save();
  }

  get ready() { return this._ready; }
}

const SINGLETON = new GeoLocalization();
export default SINGLETON;
