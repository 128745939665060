/**
* @core/windmill.geolocalization
* <br><br>
* ## Windmill GeoLocalization.
*
* - Perform Splitting.js
*
* @module windmill
* @preferred
*/

import GeoLocalization from "@core/geolocalization";

export class WindmillGeoLocalization {
  constructor() {
    this._callback = null;
    this._windmill = null;
  }

  /**
  * Plugin installation.
  */
  install(windmill) {
    this._windmill = windmill;

    windmill.on('init', this._onInit, this);
    windmill.on('loaded', this._onLoaded, this);
  }

  _onInit() {
    // fetch geolocalization without blocking ressources
    GeoLocalization
      .fetch()
      .then(() => {
        if( this._callback ) {
          this._callback();
          this._callback = null;
        }
      });
  }
  _onLoaded() {
    // wait for geolocalization
    return new Promise(resolve => {
      if( GeoLocalization.ready ) resolve();
      else this._callback = resolve;
    });
  }
}

export default WindmillGeoLocalization;
