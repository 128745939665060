import anime from "animejs";

import { $, body } from "@utils/dom";
import { moduleDelays } from "./utils";

const SELECTOR = "[data-site-loader]";

class SiteLoader {
  constructor() {
    this.el = $(SELECTOR);
    this.logo = $('.site-loader__logo', this.el);
  }

  loaded() {
    moduleDelays(350, 450);
  }

  ready() {
    return new Promise((resolve) => {
      const tl = anime.timeline({ autoplay: false });

      tl.add({
        targets: this.logo,
        scale: 0.2,
        duration: 450,
        easing: 'easeInExpo',
      }, 0);

      tl.add({
        targets: this.el,
        opacity: 0,
        duration: 100,
        easing: "linear",
      }, 350);

      tl.finished.then(() => {
        // remove from DOM when completed
        if( this.el ) this.el.remove();

        // add class on body when transition is ready
        body.classList.add("--js-ready");

        // resolve transition
        resolve();
      });

      tl.play();
    });
  }
}

export default SiteLoader;
