/**
* @core/windmill.utm
* <br><br>
* ## Windmill UTM.
*
* @module windmill
* @preferred
*/

import UTM from "@core/utm";

export class WindmillUTM {
  constructor() {
    this._callback = null;
    this._windmill = null;
  }

  /**
  * Plugin installation.
  */
  install(windmill) {
    this._windmill = windmill;
    windmill.on('loaded', this._onLoaded, this);
  }

  _onLoaded() {
    UTM.init().then(() => {
      UTM.start();
    }).catch((rejected) => {
      console.error(rejected)
    });
  }
}

export default WindmillUTM;
